@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.75rem;
  place-self: center;

  @include screen-size('medium') {
    max-width: 28rem;
  }

  h2 {
    @include text-bold;
    @include font-primary;

    color: $white;
    font-size: $spacing-lg;
    letter-spacing: -0.04rem;
    line-height: 2.25rem;

    @include screen-size('medium') {
      font-size: 3.25rem;
      letter-spacing: -0.065rem;
      line-height: 3.625rem;
    }
  }

  p {
    @include body-xl;
    @include text-normal;

    color: $white;

    &.small {
      @include body-xs;

      height: 1.25rem;

      span {
        @include text-bold;

        padding: $spacing-xxs $spacing-xs;
        border-radius: $spacing-xxs;
        margin-left: $spacing-xs;
        background-color: $white;
        color: $text-title;
      }
    }
  }

  .button {
    @include text-sm;
    @include text-bold;

    display: flex;
    width: fit-content;
    padding: 0.62rem $spacing-sm 0.62rem 0.75rem;
    border-radius: 6.25rem;
    margin-top: $spacing-md;
    background-color: $button-primary-background;
    color: $white;
    gap: 0.38rem;
  }
}

.image {
  position: relative;
  display: none;
  place-self: center;

  @include screen-size('medium') {
    display: block;
  }

  img {
    z-index: 5;
  }

  .mask {
    position: absolute;
    z-index: 0;
    bottom: -2.5rem;
  }
}

.container {
  display: grid;
  padding: $spacing-md 0 $spacing-lg;

  @include screen-size('medium') {
    padding: $spacing-md 0 3rem;
  }
}

.background {
  display: grid;
  overflow: hidden;
  max-width: 1320px;
  height: fit-content;
  padding: 3rem $spacing-md;
  border-radius: 0.75rem;
  margin: 0 1rem;
  background-image: url('https://assets.leaseloco.com/homepage/ai-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  grid-template-columns: 1fr;
  text-align: left;

  @include screen-size('medium') {
    width: 100%;
    height: 517px;
    padding: 0;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr;
  }
}
